import { normalizeNumber } from './digits';

export const getEnd = (endDate) => {
  const now = Math.floor(Date.now() / 1000);

  let range = parseInt(endDate) - now;

  if (range < 0) {
    return 'expired';
  } else if (range > 2628000) {
    const months = normalizeNumber(range / 2628000, 0);

    if (months === 1) return `in one month`;

    return `in ${months} months`;
  } else if (range > 86400) {
    return `in ${normalizeNumber(range / 86400, 0)} days`;
  } else if (range > 3600) {
    return `in ${normalizeNumber(range / 3600, 0)} hours`;
  } else if (range > 60) {
    return `in ${normalizeNumber(range / 60, 0)} min`;
  } else {
    return `in ${range} sec`;
    // } else {
    //   range = range % (24 * 3600);
    //   const hours = range / 3600;

    //   range %= 3600;
    //   const minutes = range / 60;

    //   range %= 60;
    //   const seconds = range;

    //   // return `in ${hours.toFixed(0)}:${minutes.toFixed(0)}:${seconds.toFixed(0)} hours`;
    //   return `in ${hours.toFixed(0)}:${minutes.toFixed(0)} hours`;
  }
};
