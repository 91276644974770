import { TbBrandInstagram, TbBrandTelegram, TbWorld } from 'react-icons/tb';
import { FaXTwitter } from 'react-icons/fa6';
import ShareDropdown from './dropdown/Share_dropdown';
import { Tooltip } from '@mui/material';
import Link from 'next/link';
import { BsPeople } from 'react-icons/bs';

export default function Socials({ data, isCollection, className }) {
  return (
    <div
      className={`${
        className ? className : ''
      } relative flex flex-wrap items-center justify-center space-x-2.5`}
    >
      {/* {userData?.twitter && (
        <Tooltip title={'Twitter'}>
          <div className='dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white'>
            <a
              href={`https://twitter.com/${userData?.twitter}`}
              className='relative  md:h-10 md:w-10 h-8 w-8 flex items-center justify-center'
              target='_blank'
              rel='noopener noreferrer'
            >
              <TbBrandTwitter />
            </a>
          </div>
        </Tooltip>
      )}

      {userData?.telegram && (
        <Tooltip title={'Telegram'}>
          <div className='dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white'>
            <a
              href={`https://t.me/${userData?.telegram}`}
              className='relative  md:h-10 md:w-10 h-8 w-8 flex items-center justify-center'
              target='_blank'
              rel='noopener noreferrer'
            >
              <TbBrandTelegram />
            </a>
          </div>
        </Tooltip>
      )}

      {userData?.instagram && (
        <Tooltip title={'Instagram'}>
          <div className='dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white'>
            <a
              href={`https://instagram.com/${userData?.instagram}`}
              className='relative  md:h-10 md:w-10 h-8 w-8 flex items-center justify-center'
              target='_blank'
              rel='noopener noreferrer'
            >
              <TbBrandInstagram />
            </a>
          </div>
        </Tooltip>
      )}

      {userData?.website && (
        <Tooltip title={'Website'}>
          <div className='dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white'>
            <a
              href={`${userData?.website}`}
              className='relative  md:h-10 md:w-10 h-8 w-8 flex items-center justify-center'
              target='_blank'
              rel='noopener noreferrer'
            >
              <TbWorld />
            </a>
          </div>
        </Tooltip>
      )} */}

      {data &&
        socials.map((social, i) => {
          if (data[social.name]) {
            return (
              <Tooltip
                key={social.name + i}
                title={social.name.charAt(0).toUpperCase() + social.name.slice(1)}
              >
                <div className='rounded-xl mt-2 border border-jacarta-100 bg-white hover:bg-jacarta-100 dark:border-jacarta-600 dark:bg-jacarta-700 dark:hover:bg-jacarta-600'>
                  <a
                    href={`${social.link}${data[social.name]}`}
                    className='relative flex h-10 w-10 items-center justify-center'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {social.icon}
                  </a>
                </div>
              </Tooltip>
            );
          }
        })}

      <div className='mt-2'>
        <ShareDropdown
          twitter={data?.twitter}
          instagram={data?.instagram}
          website={data?.website}
        />
      </div>
      {isCollection && data?.parentCollection && (
        <Link href={`/collection/${data?.chain}/${data?.parentCollection}`}>
          <a>
            <Tooltip title={'Collection'}>
              <div className='dark:border-jacarta-60 rounded-xl mt-2 border border-jacarta-100 bg-white hover:bg-jacarta-100 dark:bg-jacarta-700 dark:hover:bg-jacarta-600'>
                {/* <Likes data={} /> */}
                <div className='js-likes relative inline-flex h-10 w-10 cursor-pointer items-center justify-center text-sm'>
                  <button>
                    <BsPeople />
                  </button>
                </div>
              </div>
            </Tooltip>
          </a>
        </Link>
      )}
    </div>
  );
}

const socials = [
  {
    name: 'website',
    icon: <TbWorld />,
    link: '',
  },
  {
    name: 'twitter',
    icon: <FaXTwitter />,
    link: 'https://twitter.com/',
  },
  {
    name: 'telegram',
    icon: <TbBrandTelegram />,
    link: 'https://t.me/',
  },
  {
    name: 'instagram',
    icon: <TbBrandInstagram />,
    link: 'https://instagram.com/',
  },
];
