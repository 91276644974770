import React, { useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { FiShare } from 'react-icons/fi';
import toast, { Toaster } from 'react-hot-toast';
import { TbBrandTelegram } from 'react-icons/tb';
import { FaXTwitter } from 'react-icons/fa6';
import { Tooltip } from '@mui/material';
import { useAccount } from 'wagmi';

const ShareDropdown = ({
  twitter,
  instagram,
  website,
  telegram,
  breakpoint,
  width,
  buttonWidth,
  roundedLg,
  link,
  shareText,
}) => {
  const [url, setUrl] = React.useState('');
  const { address: walletAddress } = useAccount();
  useEffect(() => {
    // if (window.location.pathname === '/') {
    //   return setUrl(window.location.origin + '/user/' + walletAddress);
    // }
    if (link) return setUrl(link);
    setUrl(window.location.href);
  }, [walletAddress]);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then((r) => {
      toast.success('Link copied');
    });
  };
  return (
    <>
      {/*<Toaster position='top-right' />*/}
      <Tooltip title='Share'>
        <div
          className={`${roundedLg ? 'rounded-lg' : 'rounded-xl'} flex ${
            buttonWidth ? '' : 'h-[40px] w-[40px]'
          } items-center justify-center space-x-1 border border-jacarta-100 bg-white p-0 hover:bg-jacarta-100 dark:border-jacarta-600 dark:bg-jacarta-700 dark:hover:bg-jacarta-600`}
          style={{
            ...(buttonWidth
              ? {
                  width: buttonWidth,
                  height: buttonWidth,
                }
              : {}),
          }}
        >
          <Tippy
            animation='fade'
            arrow={false}
            trigger='click'
            interactive='true'
            placement='bottom'
            className='tooltip-container'
            content={
              <div className='rounded-xl show z-10 min-w-[200px] whitespace-nowrap bg-white py-4 px-2 text-left text-jacarta-700 shadow-xl dark:bg-jacarta-800'>
                <button onClick={copyToClipboard} className='w-full'>
                  <p className='rounded-xl flex w-full items-center px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'>
                    <span className='mt-1 inline-block'>Copy link</span>
                  </p>
                </button>
                <a
                  href={`https://t.me/share/url?url=${url}&text=${encodeURI(
                    `${
                      shareText ?? 'Check out this item on Bit5'
                    }&url=${url}&via=bit5&original_referer=${url}`,
                  )}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <p className='rounded-xl flex w-full items-center px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'>
                    <TbBrandTelegram
                      size={25}
                      className='jacarta-300 mr-2 h-4 w-4 group-hover:fill-accent dark:group-hover:fill-white'
                    />
                    <span className='mt-1 ml-2 inline-block'>Share on Telegram</span>
                  </p>
                </a>
                {/*<a*/}
                {/*    href={`https://instagram.com/${instagram}`}*/}
                {/*    target="_blank"*/}
                {/*    rel="noopener noreferrer"*/}
                {/*>*/}
                {/*    <p className="dark:hover:bg-jacarta-600 font-display hover:bg-jacarta-50 flex w-full items-center rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">*/}
                {/*        <FaFacebook size={25}/>*/}
                {/*        <span className="mt-1 ml-2 inline-block">Share on Facebook</span>*/}
                {/*    </p>*/}
                {/*</a>*/}
                <a
                  href={`https://twitter.com/intent/tweet?text=${encodeURI(
                    `${
                      shareText ?? 'Check out this item on Bit'
                    }&url=${url}&via=Bit5Official&original_referer=${url}`,
                  )}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <p className='rounded-xl flex w-full items-center px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'>
                    <FaXTwitter
                      size={25}
                      className='jacarta-300 mr-2 h-4 w-4 group-hover:fill-accent dark:group-hover:fill-white'
                    />
                    <span className='mt-1 ml-2 inline-block'>Share on Twitter</span>
                  </p>
                </a>
              </div>
            }
          >
            <button
              className={`inline-flex items-center justify-center text-sm ${
                breakpoint
                  ? `w-${width} h-${width} ${breakpoint}:h-10 ${breakpoint}:w-10`
                  : 'h-10 w-10'
              }`}
            >
              <FiShare />
            </button>
          </Tippy>
        </div>
      </Tooltip>
    </>
  );
};

export default ShareDropdown;
